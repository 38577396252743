import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Datenschutz = () => {
  const removeGACookies = () => {
    if (typeof document !== "undefined") {
      document.cookie = "_ga" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
      document.cookie = "_gid" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
      document.cookie = "hide-notice" + "=true;"
    }
  }

  return (
    <div>
      <Layout>
        <div className="container mx-auto">
          <Seo title="Datenschutz" />
          <br />
          <br />
          <h1>
            <strong>Erkl&auml;rung zur Informationspflicht&nbsp;</strong>
          </h1>
          <p></p>
          <p>&nbsp;</p>
          <h2>
            <strong>Datenschutzerkl&auml;rung</strong>
          </h2>
          <br />
          <p>
            In folgender Datenschutzerkl&auml;rung informieren wir Sie &uuml;ber
            die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer
            Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf
            Grundlage der gesetzlichen Bestimmungen (Datenschutzgrundverordnung,
            Telekommunikationsgesetz 2003).&nbsp;
          </p>
          <p>
            Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese
            besuchen wird Ihre IP-Adresse, Beginn sowie Beginn und Ende der
            Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein
            berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.&nbsp;
          </p>
          <br />
          <br />
          <p>
            <strong>Kontakt mit uns</strong>
          </p>
          <br />
          <p>
            Wenn Sie uns, entweder &uuml;ber unser Kontaktformular auf unserer
            Webseite, oder per Email kontaktieren, dann werden die von Ihnen an
            uns &uuml;bermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder
            f&uuml;r den Fall von weiteren Anschlussfragen f&uuml;r sechs Monate
            bei uns gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine
            Weitergabe Ihrer &uuml;bermittelten Daten.
          </p>
          <br />
          <br />
          <p id="cookies">
            <strong>Cookies</strong>
          </p>
          <br />
          <p>
            Unsere Website verwendet so genannte Cookies. Dabei handelt es sich
            um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem
            Endger&auml;t abgelegt werden. Sie richten keinen Schaden an. Wir
            nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten.
            Einige Cookies bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie
            diese l&ouml;schen. Sie erm&ouml;glichen es uns, Ihren Browser beim
            n&auml;chsten Besuch wiederzuerkennen. Wenn Sie dies nicht
            w&uuml;nschen, so k&ouml;nnen Sie Ihren Browser so einrichten, dass
            er Sie &uuml;ber das Setzen von Cookies informiert und Sie dies nur
            im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die
            Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein.
          </p>
          <br />
          <p>
            <span>
              Wir verwenden Google Analytics Cookies ("_ga", "_gid") um Zugriffe
              auf unsere Webseite zu tracken und zu analysieren. Wenn Sie das
              tracking abschalten möchten klicken sie{" "}
              <span
                onClick={removeGACookies}
                className="cursor-pointer font-bold"
              >
                hier
              </span>
              .
            </span>
          </p>
          <br />
          <br />
          <p>
            <strong>Google Fonts</strong>
          </p>
          <br />
          <p>
            Unsere Website verwendet Schriftarten von &bdquo;Google
            Fonts&ldquo;. Der Dienstanbieter dieser Funktion ist:&nbsp;
          </p>
          <ul className="ml-4 text-2xl mt-2 mb-4">
            <li>
              • Google Ireland Limited Gordon House, Barrow Street Dublin 4.
              Ireland&nbsp;
            </li>
          </ul>
          <p>
            Beim Aufrufen dieser Webseite l&auml;dt Ihr Browser Schriftarten und
            speichert diese in den Cache. Da Sie, als Besucher der Webseite,
            Daten des Dienstanbieters empfangen kann Google unter Umst&auml;nden
            Cookies auf Ihrem Rechner setzen oder analysieren.&nbsp;
          </p>
          <br />
          <p>
            Die Nutzung von &bdquo;Google-Fonts&ldquo; dient der Optimierung
            unserer Dienstleistung und der einheitlichen Darstellung von
            Inhalten. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
            Abs. 1 lit. f DSGVO dar.&nbsp;
          </p>
          <p>
            Weitere Informationen zu Google Fonts erhalten Sie unter folgendem
            Link:&nbsp;
          </p>
          <ul className="ml-4 text-2xl mt-2 mb-4">
            <li>
              •{" "}
              <a
                className="text-blue-700"
                href="https://developers.google.com/fonts/faq"
              >
                https://developers.google.com/fonts/faq&nbsp;
              </a>
            </li>
          </ul>
          <p>
            Weitere Informationen &uuml;ber den Umgang mit Nutzerdaten von
            Google k&ouml;nnen Sie der Datenschutzerkl&auml;rung
            entnehmen:&nbsp;
          </p>
          <ul className="ml-4 text-2xl mt-2 mb-4">
            <li>
              •{" "}
              <a
                className="text-blue-700"
                href="https://policies.google.com/privacy?hl=de"
              >
                https://policies.google.com/privacy?hl=de{" "}
              </a>
              .&nbsp;
            </li>
          </ul>
          <p>
            Google verarbeitet die Daten auch in den USA, hat sich jedoch dem{" "}
            EU-US Privacy-Shield unterworfen (
            <a
              className="text-blue-700"
              href="https://www.privacyshield.gov/EU-US-Framework"
            >
              https://www.privacyshield.gov/EU-US-Framework&nbsp;
            </a>
            ).
          </p>
          <br />
          <br />
          <p>
            <strong>Server-Log Files&nbsp;</strong>
          </p>
          <br />
          <p>
            Diese Webseite und der damit verbundene Provider erhebt im Zuge der
            Webseitennutzung automatisch Informationen im Rahmen sogenannter
            &bdquo;Server-Log Files&ldquo;. Dies betrifft insbesondere:&nbsp;
          </p>
          <ul className="ml-4 text-2xl mt-2 mb-4">
            <li>• IP-Adresse oder Hostname&nbsp;</li>
            <li>• den verwendeten Browser&nbsp;</li>
            <li>
              • Aufenthaltsdauer auf der Webseite sowie Datum und Uhrzeit&nbsp;
            </li>
            <li>• aufgerufene Seiten der Webseite&nbsp;</li>
            <li>• Spracheinstellungen und Betriebssystem&nbsp;</li>
            <li>
              • &bdquo;Leaving-Page&ldquo; (auf welcher URL hat der Benutzer die
              Webseite verlassen)&nbsp;
            </li>
            <li>• ISP (Internet Service Provider)&nbsp;</li>
          </ul>
          <p>
            Diese erhobenen Informationen werden nicht personenbezogen
            verarbeitet oder mit personenbezogenen Daten in Verbindung
            gebracht.&nbsp;
          </p>
          <p>
            Der Webseitenbetreiber beh&auml;lt es sich vor, im Falle von
            Bekanntwerden rechtswidriger T&auml;tigkeiten, diese Daten
            auszuwerten oder zu &uuml;berpr&uuml;fen.&nbsp;
          </p>
          <br />
          <br />
          <p>
            <strong>Ihre Rechte als Betroffener</strong>
          </p>
          <br />
          <p>
            Sie als Betroffener haben bez&uuml;glich Ihrer Daten, welche bei uns
            gespeichert sind grunds&auml;tzlich ein Recht auf:
          </p>
          <ul className="ml-4 text-2xl mt-2 mb-4">
            <li>• Auskunft</li>
            <li>• L&ouml;schung der Daten</li>
            <li>• Berichtigung der Daten</li>
            <li>• &Uuml;bertragbarkeit der Daten</li>
            <li>• Wiederruf und Widerspruch zur Datenverarbeitung</li>
            <li>• Einschr&auml;nkung</li>
          </ul>
          <p>
            Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten
            Verst&ouml;&szlig;e gegen das Datenschutzrecht passiert sind, so
            haben Sie die M&ouml;glichkeit sich bei uns oder der
            Datenschutzbeh&ouml;rde zu beschweren.
          </p>
          <br />
          <br />
          <p>
            <strong>Sie erreichen uns unter folgenden Kontaktdaten:</strong>
          </p>
          <br />
          <p>
            <strong>Webseitenbetreiber:</strong>&nbsp;„Zweitblick“-Lektorat
            <br />
            <strong>Telefonnummer:</strong>&nbsp;+43 670 6523764
            <br />
            <strong>Email:</strong>&nbsp;lea-gremm@zweitblick-lektorat.at
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </Layout>
    </div>
  )
}

export default Datenschutz
